$(function () {

    // Word to search with index the definitions array
    keys = {
        'auto-da-fé':       1,
        'abjuração':        2,
        'penitências':      3,
        'cristão-velho':    4,
        'cristã-velha':     4,
        'cristão-novo':     5,
        'cristã-nova':      5,
        'degredo':          6,
    }

    // Definitions of words
    definitions = {
        // auto de fé
        1: 'Solenidade pública inquisitorial em que se aplicavam as penas aos sentenciados.',
        // abjuração
        2: 'Abjuração é uma renunciação, é um termo utilizado como renúncia, diz-se sobretudo quando se trata de uma conversão ao catolicismo.',
        // penitências
        3: 'Pena imposta pelo confessor ao penitente para remissão ou expiação dos seus pecados.',
        // cristão-velho
        4: 'Cristão que não tem antepassados judeus.',
        // cristão-novo
        5: 'Judeu que se converteu ao cristianismo.',
        // degredo
        6: 'Pena de desterro, imposta judicialmente como castigo de um crime grave.',
    }

    // Replaces a words with a span with a Bootstrap tooltip with the definition
    function hintify(content) {
        for (var key in keys) {
            content = content.replace(
                new RegExp(key, "ig"),
                "<span class=\"definition\" data-toggle=\"tooltip\" title=\"" + definitions[keys[key]] + "\">$&</span>"
            );
        }
        return content
    }

    // hintify all elements with the with-hints class
    $(".with-hints").each(function(index) {
        $(this).replaceWith(hintify($(this).text()));
    });

    // Enable Bootstrap tooltips
    $('[data-toggle="tooltip"]').tooltip()
})